<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="user-details d-flex my-4">
          <p class="mx-5" :class="{ activePanel: personalInfoActive }" @click="personalInfo" v-if="false">اطلاعات کاربر سفارش دهنده</p>
          <p class="mx-5" :class="{ activePanel: userOrdersActive }" @click="orders"> جزئیات سفارش </p>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'UserDetailContainer',
  props: ['id'],
  mixins: [Helper],
  async mounted() {
    this.$rtl.enableRTL()
  },
  components: {},
  methods: {
    personalInfo() {
      this.$router.push({ path: 'orders-personal-info', replace: true })
    },

    orders() {
      this.$router.push({ path: 'orders-details', replace: true })
    }
  },
  computed: {
    personalInfoActive() {
      return this.$router.history.current.name == 'orders-personal-info'
    },
    userOrdersActive() {
      return this.$router.history.current.name == 'orders-details'
    }
  }
}
</script>

<style lang="scss" scoped>
.user-details {
  p {
    cursor: pointer;
    padding: 0 0 15px 0;
    &:hover {
      transform: scale(0.95);
    }
  }
  .activePanel {
    border-bottom: 1.5px solid #fff;
  }
}
</style>