<template>
  <div class="container-fluid px-0">
    <!-- row detail-person (first last name ) -->
    <div class="row">
      <div class="col-12">
        <div class="person-details d-flex my-1">
          <!--  name -->
          <div class="mx-2 mx-md-3 mr-xl-2">
            <span> نام و نام خانوادگی</span>
            <p class="detail-person">{{ firstName ? firstName + ' ' + lastName : 'ثبت نشده' }}</p>
          </div>

          <!-- mobile -->
          <div class="mx-2 mx-md-3 mr-xl-2">
            <span>موبایل</span>
            <p class="detail-person" dir="ltr">
              {{ phoneNumber ? phoneNumber : 'ثبت نشده' }}
            </p>
          </div>

          <!-- email -->
          <div class="mx-2 mx-md-3 mr-xl-2">
            <span> ایمیل </span>
            <p class="detail-person">{{ email ? email : 'ثبت نشده' }}</p>
          </div>

          <!-- register time -->
          <div class="mx-2 mx-md-3 mr-xl-2">
            <span> زمان ثبت نام</span>
            <p class="detail-person">{{ registerDetail.time ? jdate(registerDetail.time) : 'ثبت نشده' }}</p>
          </div>

          <!-- state -->
          <div class="mx-2 mx-md-3 mr-xl-2">
            <span> خلاصه وضعیت</span>
            <p class="detail-person">{{ summaryState ? summaryState : 'ثبت نشده' }}</p>
          </div>

          <!-- معرف -->
          <div class="mx-2 mx-md-3 mr-xl-2">
            <span> معرف</span>
            <p v-if="representative">
              <a class="detail-person" target="_blank" :href="`/#/dashboard/users/${representativeCode}/personal-info`">{{ representative ? representative : 'ثبت نشده' }}</a>
            </p>
            <p v-else>ثبت نشده</p>
          </div>

          <!-- اطلاعات IP ثبت نام و تعداد اشتراک ثبت نامی -->
          <div class="mx-2 mx-md-3 mr-xl-2">
            <span> آی پی ثبت نام</span>
            <p class="detail-person">{{ registerDetail.ip ? `${registerDetail.ip} (${registerDetail.countIp ? registerDetail.countIp : 1})` : 'ثبت نشده' }}</p>
          </div>

          <!--  پیغام سیستمی -->
          <div class="mx-2 mx-md-3 mr-xl-2">
            <span> پیغام سیستمی</span>
            <p v-if="systemMessage" class="detail-person text-danger">{{ systemMessage }}</p>
            <p class="detail-person" v-else>ثبت نشده</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="my-3">
          <div class="user-details d-flex align-content-center" ref="scrollContainer">
            <!-- user info -->
            <div class="ud-ctn" :class="{ activePanel: personalInfoActive }" @click="personalInfo">
              <div class="icon">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.21429 7.25C8.94018 7.25 10.3393 5.85089 10.3393 4.125C10.3393 2.39911 8.94018 1 7.21429 1C5.4884 1 4.08929 2.39911 4.08929 4.125C4.08929 5.85089 5.4884 7.25 7.21429 7.25Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.7625 10.8435C14.1023 11.7384 13.262 13.4998 11.6508 13.4998H2.77784C1.16661 13.4998 0.326267 11.7384 1.66616 10.8435C3.25381 9.78321 5.16187 9.16504 7.21431 9.16504C9.26675 9.16504 11.1748 9.78321 12.7625 10.8435Z"
                    stroke="white"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <p>اطلاعات شخصی</p>
            </div>

            <!-- IP -->
            <div class="ud-ctn" :class="{ activePanel: historyProfileActive }" @click="historyProfile">
              <div class="icon">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.99998 13.4616V6.72276C0.99998 5.07628 1.52671 3.81352 2.36798 2.89844" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.5 13.4617V7.80309M7.25 1C9.93738 1 12.5694 2.25364 13.3006 5.07466" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M4.59512 13.4615C4.59512 13.4615 4.26 12.4055 4.00866 10.877M9.90489 13.4615C9.90489 13.4615 10.7628 10.758 10.7628 7.74119C10.7628 7.15126 10.6866 6.65012 10.5527 6.22559"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M7.25 11.6523V12.6523" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M3.73719 7.74119C3.73719 4.7244 5.72919 4.0293 7.25001 4.0293C7.70091 4.0293 8.19324 4.0904 8.66278 4.255"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M6.07496 8.74609C6.07496 7.80813 6.60103 7.29777 7.24997 7.29777C7.89892 7.29777 8.42499 7.80813 8.42499 8.74609" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </div>
              <p>آی پی مشترک در ثبت نام</p>
            </div>

            <!-- access level -->
            <div class="ud-ctn" :class="{ activePanel: permissionProfileActive }" @click="permissionProfile">
              <div class="icon">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.20987 10.5185C1.364 11.9592 2.5227 13.1179 3.96266 13.2784C5.03221 13.3976 6.13037 13.5 7.25 13.5C8.36963 13.5 9.46779 13.3976 10.5373 13.2784C11.9773 13.1179 13.136 11.9592 13.2901 10.5185C13.4039 9.45502 13.5 8.36315 13.5 7.25C13.5 6.13684 13.4039 5.04498 13.2901 3.98147C13.136 2.54082 11.9773 1.38211 10.5373 1.22161C9.46779 1.10239 8.36963 1 7.25 1C6.13037 1 5.03221 1.10239 3.96266 1.22161C2.5227 1.38211 1.364 2.54082 1.20987 3.98147C1.09608 5.04498 1 6.13684 1 7.25C1 8.36315 1.09608 9.45502 1.20987 10.5185Z"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path d="M4.25 4.75H8.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10.25 9.75H6.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6Z"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 11C4.44772 11 4 10.5523 4 10C4 9.44772 4.44772 9 5 9C5.55228 9 6 9.44772 6 10C6 10.5523 5.55228 11 5 11Z"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <p>سطح دسترسی</p>
            </div>

            <!-- usr Log -->
            <div class="ud-ctn" :class="{ activePanel: userLogActive }" @click="userLog">
              <div class="icon">
                <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.95441 4.64453C3.77683 4.4511 3.1468 4.28235 2.08038 3.87444C2.26032 2.74693 2.4292 2.11693 2.85047 1.00041"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.00002 9.60834C1.36631 10.7385 2.08098 11.7237 3.04161 12.4228C4.00225 13.1218 5.15953 13.4988 6.34759 13.4997C7.53565 13.5006 8.6935 13.1254 9.65518 12.4278C10.6169 11.7302 11.333 10.746 11.701 9.61639C12.069 8.48675 12.0699 7.26962 11.7036 6.13943C11.3373 5.00924 10.6227 4.02402 9.66203 3.32498C8.70139 2.62593 7.54411 2.24894 6.35605 2.24805C5.16799 2.24715 4.01015 2.6224 3.04846 3.32L2.10603 3.88647"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path d="M8.38639 9.35857L7.01359 7.64258H4.95441" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <p>لاگ کاربر</p>
            </div>

            <!-- user documents -->
            <div class="ud-ctn" :class="{ activePanel: userDocumentsActive }" @click="document">
              <div class="icon">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_157_120)">
                    <path
                      d="M11.49 10.2922C11.5668 9.06826 11.6072 7.79985 11.6072 6.50014C11.6072 5.97375 11.6006 5.4525 11.5876 4.93724C11.5785 4.57528 11.461 4.22351 11.2468 3.93162C10.4301 2.81908 9.77956 2.12742 8.70733 1.29993C8.41269 1.07252 8.05112 0.949139 7.67902 0.941005C7.3059 0.932852 6.91626 0.928711 6.50003 0.928711C5.24 0.928711 4.22359 0.966662 3.17175 1.03898C2.27694 1.10051 1.56628 1.81284 1.51009 2.708C1.43327 3.93202 1.39288 5.20043 1.39288 6.50014C1.39288 7.79985 1.43327 9.06826 1.51009 10.2922C1.56628 11.1875 2.27694 11.8998 3.17175 11.9613C4.22359 12.0336 5.24 12.0716 6.50003 12.0716C7.76005 12.0716 8.77647 12.0336 9.82831 11.9613C10.7231 11.8998 11.4338 11.1875 11.49 10.2922Z"
                      stroke="white"
                      stroke-width="1.5"
                    />
                    <path d="M4.72992 6.51465H8.2701" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M4.72992 9.21273H8.25909M4.72992 3.71387H6.86624" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_157_120">
                      <rect width="13" height="13" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p>مدارک</p>
            </div>

            <!-- bank account  -->
            <div class="ud-ctn" :class="{ activePanel: userCardsActive }" @click="bankAccount">
              <div class="icon">
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 8.69317C1 9.4863 1.61923 10.139 2.40076 10.2742C3.43404 10.4529 4.41598 10.6901 7 10.6901C9.58402 10.6901 10.566 10.4529 11.5992 10.2742C12.3808 10.139 13 9.4863 13 8.69317V2.75902C13 2.06949 12.5294 1.47396 11.8485 1.36513C10.8101 1.19918 9.12191 1 7 1C4.41598 1 3.43404 1.23717 2.40076 1.41587C1.61923 1.55102 1 2.20377 1 2.99689L1 8.69317Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13 4.43842H1V2.94043C1 2.17902 1.59455 1.55334 2.34513 1.42542C2.4818 1.40212 2.61707 1.37772 2.75472 1.35288C3.67884 1.18616 4.71067 1 7 1C9.15315 1 10.8597 1.20509 11.8939 1.37244C12.5474 1.47818 13 2.05012 13 2.71207V4.43842Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M8.55505 8.06348H10.5875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <p>حساب های بانکی</p>
            </div>

            <!-- assets -->
            <div class="ud-ctn" :class="{ activePanel: userAssetActive }" @click="userAsset">
              <div class="icon">
                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.23951 10.9206C1.40186 11.8426 2.13918 12.5834 3.06473 12.7238C3.98375 12.8632 4.81362 13.0002 6.08818 13.0002C7.36275 13.0002 8.19262 12.8632 9.11164 12.7238C10.0372 12.5834 10.7745 11.8426 10.9369 10.9206C11.0621 10.2093 11.1764 9.59154 11.1764 8.39656C11.1764 7.20159 11.0621 6.58387 10.9369 5.87253C10.7745 4.95058 10.0372 4.20973 9.11164 4.06931C8.19262 3.92988 7.36275 3.79297 6.08819 3.79297C4.81362 3.79297 3.98375 3.92988 3.06473 4.06931C2.13918 4.20973 1.40186 4.95058 1.23951 5.87253C1.11425 6.58387 1 7.20159 1 8.39656C1 9.59154 1.11425 10.2093 1.23951 10.9206Z"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M10.3879 9.80207H7.51114C6.70825 9.80207 6.05737 9.15119 6.05737 8.3483C6.05737 7.5454 6.70825 6.89453 7.51114 6.89453H10.3879C10.9401 6.89453 11.3879 7.34225 11.3879 7.89453V8.80207C11.3879 9.35435 10.9401 9.80207 10.3879 9.80207Z"
                    stroke="white"
                    stroke-linecap="round"
                  />
                  <path d="M2.48407 4.244L7.62713 1.1397C8.12335 0.840196 8.76976 1.04029 9.00998 1.56777L10.5516 4.95292" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </div>
              <p>دارایی ها</p>
            </div>

            <!-- assets history -->
            <div class="ud-ctn" :class="{ activePanel: userAssetLogActive }" @click="assetLog">
              <div class="icon">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="7.25" cy="7.25" r="6.25" stroke="white" stroke-width="1.5" />
                  <path
                    d="M8.61751 5.75865C8.61751 5.75865 8.10339 5.18555 7.26954 5.25717C6.43568 5.32879 6.03374 5.84108 6.03374 6.35479C6.03374 7.85306 8.61751 6.81474 8.61751 8.35118C8.61751 9.14327 6.90359 9.7024 5.88245 8.77052"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M7.3717 4.25L7.3717 5.25132" stroke="white" stroke-linecap="round" />
                  <path d="M7.3717 9.24902L7.3717 10.2503" stroke="white" stroke-linecap="round" />
                </svg>
              </div>

              <p>سوابق دارایی ها</p>
            </div>

            <!-- Report -->
            <div class="ud-ctn" :class="{ activePanel: userStatisticalActive }" @click="userStatistical">
              <div class="icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.33477 9.0294L1.21054 9.0294C0.906732 10.2503 0.545676 12.9492 3.16137 12.9492L9.99121 12.9492C8.11626 12.9492 7.32826 10.838 8.03476 9.0294L2.33477 9.0294Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M9.85532 12.949C14.6106 12.949 8.76883 2.28766 13 1H5.44575C2.71445 1.86744 4.1183 7.99401 4.40686 9.02255" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
                  <path d="M6.8457 3.86816L8.69328 3.86816" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M6.8457 6.45117L8.69328 6.45117" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <p>گزارشات آماری</p>
            </div>

            <!-- Spot -->
            <div class="ud-ctn" :class="{ activePanel: userSpotActive }" @click="userSpot">
              <div class="icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.90613 13.092C10.596 13.0255 11.271 12.9437 11.922 12.8581C12.3696 12.7992 12.7206 12.445 12.7766 11.997C12.8569 11.3539 12.9355 10.6847 13 10"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.04165 0.908203C3.35176 0.974728 2.67674 1.05655 2.02576 1.14217C1.57817 1.20104 1.2272 1.55522 1.17121 2.00317C1.09084 2.64628 1.01232 3.31551 0.947754 4.00022"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.90613 0.908203C10.596 0.974728 11.271 1.05655 11.922 1.14217C12.3696 1.20104 12.7206 1.55522 12.7766 2.00317C12.8569 2.64628 12.9355 3.31551 13 4.00022"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.04165 13.092C3.35176 13.0255 2.67674 12.9437 2.02576 12.8581C1.57817 12.7992 1.2272 12.445 1.17121 11.997C1.09084 11.3539 1.01232 10.6847 0.947754 10"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.00003 7.24053C6.86727 7.24053 6.75964 7.13291 6.75964 7.00015C6.75964 6.86739 6.86727 6.75977 7.00003 6.75977"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.00003 7.24053C7.13279 7.24053 7.24042 7.13291 7.24042 7.00015C7.24042 6.86739 7.13279 6.75977 7.00003 6.75977"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.00003 3.24053C6.86727 3.24053 6.75964 3.13291 6.75964 3.00015C6.75964 2.86739 6.86727 2.75977 7.00003 2.75977"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.00003 3.24053C7.13279 3.24053 7.24042 3.13291 7.24042 3.00015C7.24042 2.86739 7.13279 2.75977 7.00003 2.75977"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.00003 11.2405C6.86727 11.2405 6.75964 11.1329 6.75964 11.0001C6.75964 10.8674 6.86727 10.7598 7.00003 10.7598"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.00003 11.2405C7.13279 11.2405 7.24042 11.1329 7.24042 11.0001C7.24042 10.8674 7.13279 10.7598 7.00003 10.7598"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.7596 7.00015C10.7596 6.86739 10.8673 6.75977 11 6.75977C11.1328 6.75977 11.2404 6.86739 11.2404 7.00015"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.7596 6.99985C10.7596 7.13261 10.8673 7.24023 11 7.24023C11.1328 7.24023 11.2404 7.13261 11.2404 6.99985"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.75965 7.00015C2.75965 6.86739 2.86727 6.75977 3.00003 6.75977C3.13279 6.75977 3.24042 6.86739 3.24042 7.00015"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.75965 6.99985C2.75965 7.13261 2.86727 7.24023 3.00003 7.24023C3.13279 7.24023 3.24042 7.13261 3.24042 6.99985"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <p>اسپات</p>
            </div>

            <!-- factor -->
            <div class="ud-ctn" :class="{ activePanel: userFactorActive }" @click="userFactor">
              <div class="icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 7.71984V8.59984C1 9.70441 1.89543 10.5998 3 10.5998H11C12.1046 10.5998 13 9.70441 13 8.59984V7.71984C13 6.27984 12.52 4.83984 10.6 4.83984H3.4C1.48 4.83984 1 6.27984 1 7.71984Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.15997 2V4.84H10.84V2C10.84 1.44772 10.3923 1 9.83997 1H4.15997C3.60769 1 3.15997 1.44772 3.15997 2Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.77666 9.40039C10.2353 9.75335 10.4499 10.7702 10.5303 11.7615C10.5749 12.312 10.1223 12.7604 9.56999 12.7604H4.42999C3.87771 12.7604 3.42509 12.312 3.46971 11.7615C3.55006 10.7702 3.76469 9.75335 4.22333 9.40039H9.77666Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M9.98884 7.16895L8.51111 7.16895" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <p>فاکتور</p>
            </div>

            <!-- Subset -->
            <div class="ud-ctn" :class="{ activePanel: userChildrenActive }" @click="userChildren">
              <div class="icon">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.63885 6.67628C10.9637 6.67628 12.0377 5.60226 12.0377 4.2774C12.0377 3.25254 11.395 2.37779 10.4907 2.03418"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60231 6.20071C7.03845 6.20071 8.20266 5.03649 8.20266 3.60036C8.20266 2.16422 7.03845 1 5.60231 1C4.16617 1 3.00195 2.16422 3.00195 3.60036C3.00195 5.03649 4.16617 6.20071 5.60231 6.20071Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.58535 9.85411C10.8573 10.7013 9.99842 12.3352 8.47016 12.3352H2.73108C1.20282 12.3352 0.34393 10.7013 1.61589 9.85411C2.75682 9.09422 4.127 8.65137 5.60062 8.65137C7.07424 8.65137 8.44443 9.09422 9.58535 9.85411Z"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8.24908 12.3351H11.8866C13.2965 12.3351 14.0888 10.8277 12.9154 10.0462C12.5914 9.83042 12.2474 9.64233 11.8866 9.48535"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <p>زیرمجموعه ها</p>
            </div>

            <!-- messages -->
            <div class="ud-ctn" :class="{ activePanel: userInformedActive }" @click="userInformed">
              <div class="icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.74773 0.75293C1.98251 1.51815 1.44593 2.46927 1.18195 3.50013M11.211 0.75293C11.9762 1.51815 12.5128 2.46927 12.7768 3.50013"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.53615 2.52056C5.1896 1.86711 6.07588 1.5 7 1.5C7.92413 1.5 8.8104 1.86711 9.46385 2.52056C10.1173 3.17402 10.4844 4.06029 10.4844 4.98442C10.4844 5.78109 10.6252 6.4074 10.8334 6.897C11.0423 7.38819 11.6172 7.55576 12.0462 7.87334C12.6865 8.34739 12.5627 9.36171 12.0301 9.75553C12.0301 9.75553 11.1702 10.5 7 10.5C2.82982 10.5 1.96989 9.75553 1.96989 9.75553C1.43728 9.36171 1.31348 8.34739 1.95382 7.87334C2.3828 7.55576 2.95772 7.38819 3.16658 6.897C3.37477 6.4074 3.51559 5.78109 3.51559 4.98442C3.51559 4.06029 3.88269 3.17402 4.53615 2.52056Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.76562 12.458C6.01847 12.9294 6.51599 13.2501 7.08838 13.2501C7.66078 13.2501 8.15829 12.9294 8.41114 12.458"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <p>پیام دریافتی</p>
            </div>

            <!-- add crypto req -->
            <div class="ud-ctn" :class="{ activePanel: UserDigitalCoinRequest }" @click="DigitalCoinRequest">
              <div class="icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.73853 10.5476C1.84499 10.4645 1.09401 9.81089 0.970278 8.92207C0.828692 7.905 0.75 6.85938 0.75 5.78305C0.75 4.66871 0.828721 3.57223 0.980308 2.52244C1.1002 1.69217 1.7779 1.06547 2.6122 0.977844C4.02162 0.829808 5.49414 0.75 7 0.75C8.50118 0.75 9.94769 0.834613 11.3584 0.980476C12.2069 1.06821 12.896 1.70793 13.0165 2.55241C13.1651 3.59408 13.25 4.67835 13.25 5.78305C13.25 6.87901 13.1572 7.9445 13.0111 8.979C12.8896 9.83961 12.1895 10.4964 11.3248 10.5843C9.92775 10.7262 8.48748 10.8161 7 10.8161C6.96439 10.8161 6.92881 10.816 6.89324 10.8159C5.84436 12.0224 5.04019 12.6187 3.63462 13.25V10.6306C3.40672 10.61 3.18013 10.5888 2.95493 10.5678C2.88265 10.5611 2.81052 10.5543 2.73853 10.5476Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.99686 6.40859C6.99686 6.04753 7.29051 5.82606 7.80859 5.47989C8.28914 5.1588 8.54262 4.74228 8.42987 4.17543C8.31711 3.60859 7.84874 3.14022 7.2819 3.02747C6.39211 2.85048 5.53577 3.55322 5.53577 4.46044"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M6.99609 8.48828C6.92706 8.48828 6.87109 8.43232 6.87109 8.36328C6.87109 8.29425 6.92706 8.23828 6.99609 8.23828" stroke="white" />
                  <path d="M6.99609 8.48828C7.06513 8.48828 7.12109 8.43232 7.12109 8.36328C7.12109 8.29425 7.06513 8.23828 6.99609 8.23828" stroke="white" />
                </svg>
              </div>

              <p>درخواست ارز دیجیتال</p>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-center">
            <button class="arrow right" @click="toRight" v-if="currentScrollPosition < 0">
              <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.911072 1.24414C1.83417 2.00044 2.29542 2.4616 3.01501 3.34808C2.29542 4.23455 1.83417 4.69571 0.911072 5.45201"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <button class="arrow left" @click="toLeft" v-if="-currentScrollPosition != maxScrollPosition">
              <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.911072 1.24414C1.83417 2.00044 2.29542 2.4616 3.01501 3.34808C2.29542 4.23455 1.83417 4.69571 0.911072 5.45201"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'UserDetailContainer',
  props: ['id'],
  mixins: [Helper],
  components: {},
  data() {
    return {
      currentScrollPosition: 0,
      maxScrollPosition: 0,
    }
  },
  methods: {
    userChildren() {
      this.$router.push({ path: 'user-children', replace: true })
    },
    userInformed() {
      this.$router.push({ path: 'user-informed', replace: true })
    },

    userStatistical() {
      this.$router.push({ path: 'user-statistical-reports', replace: true })
    },
    personalInfo() {
      this.$router.push({ path: 'personal-info', replace: true })
    },
    userLog() {
      this.$router.push({ path: 'user-log', replace: true })
    },
    historyProfile() {
      this.$router.push({ path: 'history-profile', replace: true })
    },
    permissionProfile() {
      this.$router.push({ path: 'permission', replace: true })
    },
    assetLog() {
      this.$router.push({ path: 'asset-log', replace: true })
    },
    document() {
      this.$router.push({ path: 'user-documents', replace: true })
    },
    bankAccount() {
      this.$router.push({ path: 'user-cards', replace: true })
    },
    DigitalCoinRequest() {
      this.$router.push({ path: 'digital-coin-request', replace: true })
    },
    userFactor() {
      this.$router.push({ path: 'user-factor', replace: true })
    },
    userSpot() {
      this.$router.push({ path: 'spot', replace: true })
    },
    userAsset() {
      this.$router.push({ path: 'user-asset', replace: true })

    },
    toLeft() {
      const containerWidth = this.$refs.scrollContainer.offsetWidth;
      const contentWidth = this.$refs.scrollContainer.scrollWidth;
      const scrollAmount = contentWidth * 0.25;
      this.$refs.scrollContainer.scrollLeft -= scrollAmount;
    },
    toRight() {
      const containerWidth = this.$refs.scrollContainer.offsetWidth;
      const contentWidth = this.$refs.scrollContainer.scrollWidth;
      const scrollAmount = contentWidth * 0.25;
      this.$refs.scrollContainer.scrollLeft += scrollAmount;
    },

  },
  computed: {
    showLeftButton() {
      return this.currentScrollPosition > 0;
    },
    showRightButton() {
      return this.currentScrollPosition < this.maxScrollPosition;
    },
    userChildrenActive() {
      return this.$router.history.current.name == 'user-children'
    },
    userInformedActive() {
      return this.$router.history.current.name == 'user-informed'
    },
    historyProfileActive() {
      return this.$router.history.current.name == 'history-profile'

    },
    userStatisticalActive() {
      return this.$router.history.current.name == 'user-statistical'
    },
    personalInfoActive() {
      return this.$router.history.current.name == 'personal-info'
    },
    userLogActive() {
      return this.$router.history.current.name == 'user-log'
    },
    permissionProfileActive() {
      return this.$router.history.current.name == 'permission'
    },
    userAssetLogActive() {
      return this.$router.history.current.name == 'asset-log'
    },
    userDocumentsActive() {
      return this.$router.history.current.name == 'user-documents'
    },
    userCardsActive() {
      return this.$router.history.current.name == 'user-cards'
    },
    UserDigitalCoinRequest() {
      return this.$router.history.current.name == 'digital-coin-request'
    },
    userFactorActive() {
      return this.$router.history.current.name == 'user-factor'
    },
    userSpotActive() {
      return this.$router.history.current.name == 'spot'
    },
    userAssetActive() {
      return this.$router.history.current.name == 'user-asset'
    },

    /** ----------------------- start get profile */
    userID: {
      get() {
        return this.$store.state.at.uuid
      },
      set() {

      }
    },
    firstName: {
      get() {
        return this.$store.state.at.firstName
      },
      set() {

      }
    },
    lastName: {
      get() {
        return this.$store.state.at.lastName
      },
      set() {

      }
    },
    nationality: {
      get() {
        return this.$store.state.at.nid
      },
      set() {

      }
    },
    phoneNumber: {
      get() {
        return this.$store.state.at.mobile
      },
      set() {

      }
    },

    birthday: {
      get() {
        return this.$store.state.at.birthDate
      },
      set() {

      }
    },
    codeMeli: {
      get() {
        return this.$store.state.at.codeMeli
      },
      set() {

      }
    },
    email: {
      get() {
        return this.$store.state.at.email
      },
      set() {

      }
    },
    picAcceptance: {
      get() {
        return this.$store.state.at.picAcceptance
      },
      set() {
      }
    },
    picCodeMeli: {
      get() {
        return this.$store.state.at.picCodeMeli
      },
      set() {
      }
    },
    avatar: {
      get() {
        return this.$store.state.at.avatar
      },
      set() {
      }
    },
    stateAcceptance: {
      get() {
        return this.$store.state.at.stateAcceptance
      },
      set(value) {
        this.$store.commit('at/setStateAcceptance', value);
      }
    },
    stateCodeMeli: {
      get() {
        return this.$store.state.at.stateCodeMeli
      },
      set(value) {
        this.$store.commit('at/setStateCodeMeli', value);
      }
    },

    registerDetail: {
      get() {
        return this.$store.state.at.registerDetail
      },
      set() {

      }
    },
    summaryState: {
      get() {
        return this.$store.state.at.summaryState
      },
      set() {

      }
    },
    representative: {
      get() {
        return this.$store.state.at.representative
      },
      set() {

      }
    },
    representativeCode: {
      get() {
        return this.$store.state.at.representativeCode
      },
      set() {

      }
    },
    systemMessage: {
      get() {
        return this.$store.state.at.systemMessage
      },
      set() {

      }
    },
    /** ----------------------- end get profile */

  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get user info */
    // this.$store.dispatch('at/initProfile', this.$router.history.current.params.id)

    this.maxScrollPosition = this.$refs.scrollContainer.scrollWidth - this.$refs.scrollContainer.offsetWidth;

    this.$refs.scrollContainer.addEventListener("scroll", () => {
      this.currentScrollPosition = this.$refs.scrollContainer.scrollLeft;
      this.maxScrollPosition = this.$refs.scrollContainer.scrollWidth - this.$refs.scrollContainer.offsetWidth;
    });
  }
}
</script>

<style lang="scss" scoped>
.user-details {
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: auto;
  padding-bottom: 5px;
  transition: all 0.3s linear;
  scroll-behavior: smooth;
  /* scroll bar 'not supported in IOS' */
  &::-webkit-scrollbar {
    height: 4px;
    display: none;
  }
  .ud-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 0 0 8px 0;
    transition: 0.2s linear all;
    margin: 0 12px;
    .icon {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff0a;
      svg {
        transform: scale(1.5);
      }
    }
    p {
      font-size: 15px !important;
      margin: 5px 0 0 0 !important;
      opacity: 0.6;
      white-space: nowrap;
    }

    &:hover {
      transform: scale(0.98);
    }
    &.activePanel {
      border-bottom: 1.5px solid #ffffff6b;
      .icon {
        background-color: #ffffff14;
        box-shadow: rgba(255, 255, 255, 0.16) 0px 1px 4px;
      }
      p {
        opacity: 1;
      }
    }
  }
  @media (max-width: 1560px) and( min-width: 992px) {
    p {
      font-size: 8px !important;
    }
  }
}

.person-details {
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 0 0 15px 0;
    font-size: 13.5px !important;

    &:hover {
      transform: scale(0.95);
    }
  }
  .activePanel {
    border-bottom: 1.5px solid #fff;
  }
  @media (max-width: 1560px) and( min-width: 992px) {
    p {
      font-size: 13px !important;
    }
  }
}

.detail-user {
  .detail-person,
  span {
    font-size: 14px !important;
    display: flex;
    align-items: inherit;
  }
}

.arrow {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff1a;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
  margin: 0 2.5px;
  outline: none;
  svg {
    transform: scale(1.5);
  }
  &.left {
    svg {
      transform: scaleX(-1) scale(1.5);
    }
  }
}
@media (max-width: 992px) {
  .detail-user {
    margin-right: 20%;
  }
}
</style>