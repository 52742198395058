<template>
  <section class="trader-level-edit">
    <div class="container-fluid">
      <div class="row">
        <!-- card on for title and logo and status -->
        <div class="col-12">
          <ValidationObserver ref="contentValidation">
            <card>
              <div class="card-header d-flex justify-content-between">
                <h4>ویرایش سطح کاربری</h4>
                <!-- isDisabled -->
                <div class="d-flex align-items-center">
                  <span>وضعیت</span>
                  <div class="switch mx-2">
                    <input type="checkbox" class="toggle" v-model="isDisabled" />
                  </div>
                </div>
              </div>
              <!-- the page content  -->
              <div class="card-body" v-cloak>
                <!-- title -->
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 mb-2">
                    <ValidationProvider name="عنوان" rules="required" v-slot="{ errors }">
                      <span>عنوان </span>
                      <input type="text" v-model="title" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- logo -->
                  <div class="col-12 col-sm-6 col-md-4 mb-2">
                    <ValidationProvider name="لوگو سطح" rules="required" v-slot="{ errors }">
                      <span>آدرس لوگو سطح</span>
                      <div class="mt-1 d-flex align-items-center justify-content-between">
                        <img :src="logoUrl" v-if="logoUrl" class="logo-coin" alt="" />
                        <input dir="ltr" type="text" v-model="logoUrl" />
                      </div>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- logo -->
                  <div class="col-12 col-sm-6 col-md-4 mb-2">
                    <ValidationProvider name=" لوگو جایزه" rules="" v-slot="{ errors }">
                      <span>آدرس لوگو جایزه</span>
                      <div class="mt-1 d-flex align-items-center justify-content-between">
                        <img :src="prizeUrl" v-if="prizeUrl" class="logo-coin" alt="" />
                        <input dir="ltr" type="text" v-model="prizeUrl" />
                      </div>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- minVolume -->
                  <div class="col-12 col-sm-6 col-md-4 mb-2">
                    <ValidationProvider name="حداقل حجم" rules="required|double|min_value:0" v-slot="{ errors }">
                      <span> حداقل حجم </span>
                      <input type="text" label=" حداقل حجم" v-model="minVolume" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- maxVolume -->
                  <div class="col-12 col-sm-6 col-md-4 mb-2">
                    <ValidationProvider name="حداکثر حجم" rules="required|double|min_value:0" v-slot="{ errors }">
                      <span> حداکثر حجم </span>
                      <input type="text" label=" حداکثر حجم" v-model="maxVolume" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- discountOnCommission -->
                  <div class="col-12 col-sm-6 col-md-4 mb-2">
                    <ValidationProvider name="درصد تخفیف" rules="required" v-slot="{ errors }">
                      <span> درصد تخفیف </span>
                      <input type="text" label=" درصد تخفیف" v-model="discountOnCommission" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- conditions -->
                  <div class="col-12 col-md-6">
                    <ValidationProvider name="شرط" rules="required" v-slot="{ errors }">
                      <p>شرط</p>
                      <vue2-tinymce-editor v-model="conditions" :options="options" class="mt-3"></vue2-tinymce-editor>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <!-- prizes -->
                  <div class="col-12 col-md-6">
                    <ValidationProvider name="جوایز" rules="required" v-slot="{ errors }">
                      <p>جوایز</p>
                      <vue2-tinymce-editor v-model="prizes" :options="options" class="mt-3"></vue2-tinymce-editor>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </card>
          </ValidationObserver>
        </div>
        <!-- submit btn -->
        <div class="col-12">
          <div class="submit d-flex justify-content-start align-items-center pt-3">
            <button class="btn-primary mx-1" @click="submit" v-if="!isLoading">ذخیره</button>
            <div v-else class="loader-wait-request mx-5"></div>

            <button class="btn-light mx-1" @click="$router.go(-1)">انصراف</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

/**import mixin */
import Helper from '../../../settings/Helper'


/**import validations */
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import { required, double, min_value } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('double', double)
extend('min_value', min_value)

localize('fa', fa)


/** tinymce */
import { Vue2TinymceEditor } from "vue2-tinymce-editor";

export default {
  name: 'edit-trader-level',
  mixins: [Helper],
  data() {
    return {
      isLoading: false,

      id: null,
      title: null,
      logoUrl: null,
      prizeUrl: null,
      conditions: null,
      prizes: null,
      countOfUsers: null,
      minVolume: null,
      maxVolume: null,
      discountOnCommission: null,
      isDisabled: null,
      log: null,

      // tinyMce
      options: {
        directionality: "rtl",
      },
    }
  },
  methods: {
    /**save title and logo trader */
    async submit() {
      try {
        if (this.isLoading) return
        if (! await this.$refs.contentValidation.validate()) return this.$notify({ text: 'لطفا خطاهای رخ داده را برطرف نمایید', type: 'error' })

        this.isLoading = true

        let data = {
          idLang: 1065,
          id: this.id,
          title: this.title,
          logoUrl: this.logoUrl,
          prizeUrl: this.prizeUrl,
          conditions: this.conditions,
          prizes: this.prizes,
          minVolume: this.minVolume,
          maxVolume: this.maxVolume,
          discountOnCommission: this.discountOnCommission,
          isDisabled: !this.isDisabled,
        }

        await this.$axios({
          url: 'organizer/traderLevel/set',
          data: data,
        })


        /**proceed step 1 */
        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.$router.go(-1)

      } catch (error) {
        this.$notify({
          text: 'خطا در عملیات. لطفا مجدد تلاش نمائید',
          type: 'warn'
        })
      }

      this.isLoading = false


    },
  },
  async mounted() {
    this.$rtl.enableRTL()

    try {

      this.id = this.$router.history.current.params.id
      let res = await this.$axios({
        method: 'get',
        url: `Organizer/traderLevel/Get`,
        headers: {
          idLang: '1065',
          idTraderLevel: this.id
        }
      })
      res = res.data.data

      this.id = res.id;
      this.title = res.title;
      this.logoUrl = res.logoUrl;
      this.prizeUrl = res.prizeUrl;
      this.conditions = res.conditions;
      this.prizes = res.prizes;
      this.minVolume = res.minVolume;
      this.maxVolume = res.maxVolume;
      this.discountOnCommission = res.discountOnCommission;
      this.isDisabled = !res.isDisabled;


    } catch (error) {
      console.log(error)
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    Vue2TinymceEditor
  },
}

</script>

<style lang="scss" >
.trader-level-edit {
  input[type='text'],
  input[type='password'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    margin-top: -9px;
    margin-bottom: 13px;
    &:disabled {
      color: #9b9b83;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    margin: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
  .dropzone {
    position: relative;
    .dropzone-area {
      background: #cecece;
      border-radius: 9px;
      margin: 20px;
      padding: 30px;

      position: relative;
      .dotted-border {
        position: absolute;
        width: 94%;
        height: 86%;
        top: 7%;
        left: 2%;
        border: 2px dashed #707070;
        border-radius: 12px;
        z-index: 0;
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        color: #707070;
        min-width: 100px;
      }
    }
    .viewer {
      z-index: 4;
      img {
        max-width: 122px;
        min-width: 100px;
      }
    }
    label {
      width: 170px;
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    input[type='file'] {
      opacity: 0;
      outline: none;
    }
    .bg-green {
      background: #33c933;
      border: #33c933;
      color: white;
      svg {
        stroke: white;
      }
    }
  }

  .vm--modal {
    width: unset !important;
  }

  .change-pass {
    display: block;
    margin-top: 40px;
    cursor: pointer;
    span {
      color: #9b9b83;
    }
    &:hover {
      color: #9b9b83 !important;
      span {
        color: #e6e6a4;
      }
    }
  }
  .input_container {
    width: 100% !important;
    background: silver !important;
  }
  .lstConditionsJson,
  .prize {
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0 3px 6px #383a50;
  }
}
</style>
