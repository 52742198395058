<template>
  <section class="login-section">
    <div class="login-content">
      <div class="login-header">
        <h3 class="m-0">ورود مدیریت</h3>
      </div>

      <!-- logo brand -->
      <div class="brand">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.062" height="46.469" viewBox="0 0 32.062 46.469">
          <g id="Group_745" data-name="Group 745" transform="translate(-509.893 -437.461)">
            <path
              id="Path_1777"
              data-name="Path 1777"
              d="M864.739,457.912a7.585,7.585,0,0,0-4.6-3.15,12.585,12.585,0,0,0,4.833-3.046,8.326,8.326,0,0,0,2.321-4.518q.789-4.831-2.091-7.282t-9.362-2.455H842.8l-5.961,36.512h13.83c.1,0,.2,0,.3,0l.644-3.945c-.065,0-.128,0-.192,0h-8.68l2.146-13.144h8.78q7.834,0,6.76,6.607a7.085,7.085,0,0,1-2.917,4.8,7.748,7.748,0,0,1-.946.587l-.742,4.548a14.418,14.418,0,0,0,5.151-2.264,11.342,11.342,0,0,0,4.744-7.714A7.587,7.587,0,0,0,864.739,457.912Zm-5.493-6.453a10.123,10.123,0,0,1-5.8,1.577h-7.939l1.895-11.6h7.784q3.91,0,5.61,1.365t1.214,4.377A6.2,6.2,0,0,1,859.246,451.458Z"
              transform="translate(-326.942)"
              fill="#fff"
            />
            <path id="Path_1778" data-name="Path 1778" d="M867.026,503.487h18.913l-.644,3.945H861.1l5.96-36.509h5.283Z" transform="translate(-343.984 -23.502)" fill="#fff" />
          </g>
        </svg>
      </div>

      <!-- component failed recaptcha -->
      <BtnFailedRecaptcha v-if="recaptchaLoadedFailed"></BtnFailedRecaptcha>
      <!-- login form -->
      <div v-if="!verifyStatus">
        <!-- login elements  -->
        <ValidationObserver ref="loginToPanel">
          <div class="login-main">
            <!-- type login -->
            <div dir="ltr" class="my-3">
              <p-radio class="p-default p-curve" name="color" value="mobile" color="warning-o" v-model="typeLogin">ورود با موبایل</p-radio>
              <p-radio class="p-default p-curve" name="color" value="email" color="warning-o" v-model="typeLogin">ورود با ایمیل</p-radio>
              <!-- <p-radio class="p-default p-curve" value="email" name="color" color="warning-o" v-model="typeLogin">1</p-radio> -->
              <!-- <p-radio class="p-default p-curve" value="mobile" name="color" color="warning-o" v-model="typeLogin">2</p-radio> -->
            </div>
            <ValidationProvider rules="required|mobile" name="شماره همراه" v-slot="{ errors }" v-if="typeLogin == 'mobile'">
              <div class="d-flex justify-content-center country-code">
                <!-- <vue-country-code @onSelect="getCode" :onlyCountries="['ir']" :disabledFetchingCountry="true"> </vue-country-code> -->
                <div id="countryCodeNumber"></div>
                <input autocomplete="off" type="text" @keypress="isNumber($event)" v-model="phoneNumber" placeholder="شماره همراه " />
              </div>
              <div>
                <span class="error">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <!-- email  -->
            <ValidationProvider rules="required|email" name="ایمیل" v-slot="{ errors }" v-else>
              <div class="d-flex justify-content-center country-code">
                <!-- <vue-country-code @onSelect="getCode" :onlyCountries="['ir']" :disabledFetchingCountry="true"> </vue-country-code> -->
                <div id="countryCodeNumber"></div>
                <input autocomplete="off" type="text" v-model="email" placeholder=" ایمیل خود را وارد کنید" />
              </div>
              <div>
                <span class="error">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <ValidationProvider name=" رمز عبور" rules="required" v-slot="{ errors }">
              <!--password-->
              <div class="password">
                <div class="label d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.235" height="23.424" viewBox="0 0 17.235 23.424">
                    <path id="lock" d="M194.214,72.8v-3.2a7.093,7.093,0,1,0-14.185,0v3.2H178.5V85.924h17.235V72.8Zm-11.519-3.2a4.422,4.422,0,0,1,8.845,0v3.2h-8.845Zm5.646,12.727h-2.433l.364-3.6a1.6,1.6,0,1,1,1.695,0Z" transform="translate(-178.5 -62.5)" fill="#525f7f" />
                  </svg>

                  <p class="px-2 my-0">گذرواژه :</p>
                </div>
                <div class="d-flex my-3">
                  <input autocomplete="off" type="password" name="password" id="password" v-model="password" @keyup.enter="sendVerify" placeholder="گذرواژه" />
                </div>
              </div>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>

            <!-- login with 2FA -->
            <div v-if="typeLogin == 'email'" class="switch-authenticator d-flex flex-row-reverse align-items-center justify-content-between my-3">
              <div class="d-flex flex-row-reverse flex-end align-items-center">
                <img src="../../../public/img/Authenticator.png" alt="icon" />
                <span class="mx-1">2FA</span>
                <input type="checkbox" id="authenticator" v-model="googleAuthenticatorStatus" /><label for="authenticator" class="mt-2"></label>
              </div>
              <div class="info-text"><p>ورود دو مرحله با 2FA</p></div>
            </div>
            <a href="#" @click.prevent="resetPassword" class="forget-password my-1"> کلمه عبور خود را <span>فراموش</span> کرده اید؟ </a>
          </div>
        </ValidationObserver>

        <!-- Ar captcha -->
        <div class="arcaptcha-container" v-if="captchaType == 'ArCaptcha'">
          <ArCaptcha :key="themeKey" :invisible="false" v-model="arCaptchaToken" :site_key="arcaptchaSiteKey" :theme="theme"></ArCaptcha>
        </div>
        <span v-if="arcaptchaRequired" class="error">کپچا الزامی است</span>

        <div class="login-footer">
          <button v-if="!isLoading" class="btn-warning" @click="sendVerify">مرحله بعد</button>
          <!-- loader -->
          <div v-else class="submit d-flex justify-content-center align-items-center pt-3">
            <div class="loader-wait-request mx-5"></div>
          </div>
        </div>
      </div>

      <!-- verify form -->
      <div v-else>
        <!-- pin code input  -->
        <div class="d-flex justify-content-center pincode" dir="ltr">
          <PincodeInput :length="6" v-model="pinCode" />
        </div>
        <div class="login-footer">
          <button v-if="!isLoading" class="btn-warning" @click="checkIdentification">ورود</button>
          <!-- loader -->
          <div v-else class="submit d-flex justify-content-center align-items-center pt-3">
            <div class="loader-wait-request mx-5"></div>
          </div>
        </div>

        <!-- resend code -->
        <!-- disabled countdown  -->
        <div v-if="!googleAuthenticatorStatus">
          <span class="resend-disabled d-flex align-items-center justify-content-start my-3" v-if="!resend">
            <svg xmlns="http://www.w3.org/2000/svg" class="mx-1" width="19.513" height="11.869" viewBox="0 0 19.513 11.869">
              <path
                id="retweet"
                d="M67.278,242.807v-4.575a.294.294,0,0,1,.294-.294h1.409c.163,0,.207-.1.1-.221l-3.073-3.5a.251.251,0,0,0-.388,0l-3.075,3.5c-.107.123-.062.221.1.221h1.409a.294.294,0,0,1,.294.294v5.659a1.977,1.977,0,0,0,1.978,1.978h8.589c.163,0,.205-.1.1-.216l-2.136-2.337a.757.757,0,0,0-.493-.216H67.573A.294.294,0,0,1,67.278,242.807Zm12.859-1.168v-5.661A1.977,1.977,0,0,0,78.159,234H69.571c-.163,0-.205.1-.1.216l2.136,2.337a.757.757,0,0,0,.493.216h4.812a.294.294,0,0,1,.294.294v4.575a.294.294,0,0,1-.294.294H75.506c-.163,0-.207.1-.1.221l3.075,3.5a.251.251,0,0,0,.388,0l3.075-3.5c.107-.123.062-.221-.1-.221H80.434A.3.3,0,0,1,80.137,241.639Z"
                transform="translate(-62.489 -234)"
                fill="#707070"
              />
            </svg>
            ارسال مجدد کد تا
            <VueCountdown dir="ltr" @end="onCountdownEnd" :time="timer" :interval="100" v-slot="{ minutes, seconds }" class="mx-1"> {{ minutes }} : {{ seconds }} </VueCountdown>
            ثانیه دیگر
          </span>
          <!-- resend verify code btn  -->
          <span class="resend d-flex align-items-center justify-content-start my-3" @click="resendVerify" v-if="resend">
            <svg xmlns="http://www.w3.org/2000/svg" class="mx-1" width="19.513" height="11.869" viewBox="0 0 19.513 11.869">
              <path
                id="retweet"
                d="M67.278,242.807v-4.575a.294.294,0,0,1,.294-.294h1.409c.163,0,.207-.1.1-.221l-3.073-3.5a.251.251,0,0,0-.388,0l-3.075,3.5c-.107.123-.062.221.1.221h1.409a.294.294,0,0,1,.294.294v5.659a1.977,1.977,0,0,0,1.978,1.978h8.589c.163,0,.205-.1.1-.216l-2.136-2.337a.757.757,0,0,0-.493-.216H67.573A.294.294,0,0,1,67.278,242.807Zm12.859-1.168v-5.661A1.977,1.977,0,0,0,78.159,234H69.571c-.163,0-.205.1-.1.216l2.136,2.337a.757.757,0,0,0,.493.216h4.812a.294.294,0,0,1,.294.294v4.575a.294.294,0,0,1-.294.294H75.506c-.163,0-.207.1-.1.221l3.075,3.5a.251.251,0,0,0,.388,0l3.075-3.5c.107-.123.062-.221-.1-.221H80.434A.3.3,0,0,1,80.137,241.639Z"
                transform="translate(-62.489 -234)"
                fill="#707070"
              />
            </svg>
            ارسال مجدد
          </span>
        </div>
      </div>

      <!-- app commit id before login -->
      <div class="gitCommit" v-if="showCommit">
        {{ gitCommit }}
      </div>
    </div>
  </section>
</template>

<script>

var mobileReg = /(0|\+98)?([ ]|-|[()]){0,2}9[0|1|2|3|4|9]([ ]|-|[()]){0,2}(?:[0-9]([ ]|-|[()]){0,2}){8}/gi,
  junkReg = /[^\d]/gi,
  persinNum = [/۰/gi, /۱/gi, /۲/gi, /۳/gi, /۴/gi, /۵/gi, /۶/gi, /۷/gi, /۸/gi, /۹/gi],
  num2en = function (str) {
    for (var i = 0;i < 10;i++) {
      str = str.replace(persinNum[i], i)
    }
    return str
  },
  getMobiles = function (str) {
    var mobiles = num2en(str + '').match(mobileReg) || []
    mobiles.forEach(function (value, index, arr) {
      arr[index] = value.replace(junkReg, '')
      arr[index][0] === '0' || (arr[index] = '0' + arr[index])
    })
    return mobiles
  }


/**import validations */
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('email', email)
extend('mobile', {
  validate: (value) => {
    return getMobiles(value).length
  },
  message: 'شماره همراه وارد شده صحیح نمی باشد'
})
localize('fa', fa)

/**import auth methods */
import { setToken, setRefreshToken, get2FA, set2FA, remove2FA } from "../../settings/AuthApi"

// import Base64 for encode package
import { Base64 } from 'js-base64';

/**the resend countdown */
import VueCountDown from "@chenfengyuan/vue-countdown";

/**the pincode input */
import PincodeInput from "vue-pincode-input";

/** Pretty Checkbox */
import PrettyRadio from 'pretty-checkbox-vue/radio';

/** import ar-captcha */
import ArCaptcha from 'arcaptcha-vue';


// import component failed recaptcha
import BtnFailedRecaptcha from '../../components/BtnFailedRecaptcha'

export default {
  name: "Login",
  data() {
    return {
      phoneNumber: null,
      password: null,
      verifyStatus: false,
      pinCode: '',
      resend: false,
      typeLogin: 'email',
      email: null,
      googleAuthenticatorStatus: false,
      isLoading: false,

      /** Arcaptcha */
      arCaptchaToken: null,
      arcaptchaRequired: false,
      theme: 'dark',
      themeKey: 0
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    VueCountdown: VueCountDown,
    PincodeInput,
    'p-radio': PrettyRadio,
    ArCaptcha,
    BtnFailedRecaptcha
  },
  methods: {
    // reset password
    resetPassword() {
      this.$router.push({ path: '/reset-password' })
    },

    /**send verify code to user*/
    async sendVerify() {

      if (this.isLoading) return

      if (! await this.$refs.loginToPanel.validate()) return

      /**request login 
       * default request register via email
       * else register via mobile
      */
      try {
        this.isLoading = true

        /** Start get captcha */
        let captchaToken = null;
        if (this.captchaType == 'ArCaptcha') {
          if (!this.arCaptchaToken) {
            this.arcaptchaRequired = true
            this.isLoading = false
            return
          }
          captchaToken = this.arCaptchaToken;
        }
        else if (this.captchaType == 'ReCaptcha') { captchaToken = await this.$recaptcha('login') }

        /**do not send request if captcha not accepted and type != ignoreCaptcha */
        if (!captchaToken && this.captchaType != 'ignoreCaptcha') {
          this.isLoading = false
          return
        }
        /** End get captcha */


        let url = 'auth/LoginViaEmail'
        let data = {
          idLang: 1065,
          emailAddress: this.email,
          captchaToken: captchaToken,
          password: Base64.encode(this.password),
          uRefereeCode: this.IntroducedCode
        }
        if (this.typeLogin == 'mobile') {
          url = 'auth/LoginViaMobile'
          data = {
            idLang: 1065,
            mobilePrefix: "+98",
            mobileNumber: this.phoneNumber.substring(1),
            captchaToken: captchaToken,
            password: Base64.encode(this.password),
            uRefereeCode: this.IntroducedCode
          }

          this.googleAuthenticatorStatus = false
        }

        /** login wia google authenticator */
        if (this.googleAuthenticatorStatus && this.typeLogin == 'email') {
          url = 'auth/LoginViaEmail2F'
          data = {
            idLang: 1065,
            emailAddress: this.email,
            captchaToken: captchaToken,
            password: Base64.encode(this.password),
          }
        }

        // request for login via mobile and email
        await this.$axios({
          url: url,
          data: data,
        })

        // show verify form
        this.verifyStatus = true;
        this.isLoading = false

      } catch (error) {
        console.log(error)
        this.isLoading = false

        return
      }

    },
    /**resend verify code */
    async resendVerify() {
      try {
        this.pinCode = null;
        // const captchaToken = await this.$recaptcha('resend')


        let url = 'auth/ResendIdentificationViaEmail'
        let data = {
          idLang: 1065,
          emailAddress: this.email,
          // captchaToken: captchaToken,

        }
        if (this.typeLogin == 'mobile') {
          url = 'auth/ResendIdentificationViaMobile'
          data = {
            idLang: 1065,
            mobilePrefix: "+98",
            mobileNumber: this.phoneNumber.substring(1),
            // captchaToken: captchaToken,
          }
        }

        // request for resend code
        await this.$axios({
          url: url,
          data: data,
        })
      } catch (error) {
        return;
      }
      this.resend = false;

    },
    /**Check pincode and next step
     */
    async checkIdentification() {
      /**validate token inserted or not */
      // if (!this.pincodeFilled) return this.$notify({ text: "وارد کردن پین کد الزامی است", type: "error" })

      try {
        if (this.isLoading) return
        this.isLoading = true

        let url = 'auth/CheckIdentificationViaEmail'
        let data = {
          idLang: 1065,
          emailAddress: this.email,
          receivedCode: this.pinCode
        }
        if (this.typeLogin == 'mobile') {
          url = 'auth/CheckIdentificationViaMobile'
          data = {
            idLang: 1065,
            mobilePrefix: '+98',
            mobileNumber: this.phoneNumber.substring(1),
            receivedCode: this.pinCode
          }
        }

        let TokenCode = null
        if (!this.googleAuthenticatorStatus) {
          // request for Check Identification 
          let RefreshTokenCode = await this.$axios({
            url: url,
            data: data,
          })

          /**request for get jwtToken and refreshTokenCode */
          TokenCode = await this.$axios({
            url: 'auth/TokenGet',
            headers: {
              refreshTokenCode: RefreshTokenCode.data.data,
              adminsOnly: true
            },
            method: 'get'
          })

        }
        else {
          /**request for get jwtToken and refreshTokenCode */
          TokenCode = await this.$axios({
            url: 'Auth/TokenGetAfter2F',
            headers: {
              twoFactorCode: this.pinCode,
              emailAddress: this.email,
              adminsOnly: true
            },
            method: 'get'
          })
        }
        setToken(TokenCode.data.data.jwtToken)
        setRefreshToken(TokenCode.data.data.refreshTokenCode)


        /** set 2FA in localStorage for login auto with 2FA */
        if (this.googleAuthenticatorStatus)
          set2FA(true)
        else
          remove2FA()


        // this.$store.commit('setAuthenticated', true)
        this.$router.push("dashboard", true)
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false

    },
    // get just number
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    /**count down end event */
    onCountdownEnd() {
      this.resend = true;
    },

    /** detect type captcha
       * GoogleRecaptchaV3 == 11 And ArcaptchaV3 == 21 and ignore captcha==0
       */
    async detectTypeCaptcha() {
      try {
        // request get type captcha
        let req = await this.$axios({
          url: "/General/CaptchaMethodTypeGet",
          method: 'get'
        })

        /** if req == 21 ArcaptchaV3 else (for code 11) use GoogleRecaptchaV3 */
        if (req.data.data == 21) return this.$store.dispatch("at/loadArcaptcha", true)

        /** ignore captcha */
        if (req.data.data == 0) return this.$store.dispatch("at/ignoreCaptcha", true)

        // Wait until recaptcha has been loaded.
        try {
          this.$store.dispatch("at/loadRecaptcha", await this.$recaptchaLoaded())
        } catch (error) {
          this.$store.dispatch("at/loadRecaptcha", false)
        }
      } catch (error) {
        console.log(error);
      }

    },

    /** Activities that we should do while being offline */
    offlineAction() {
      this.$store.dispatch("at/loadRecaptcha", false)
    }
  },
  computed: {
    timer() {
      return parseInt(window.env.VUE_APP_TIMER)
    },

    captchaType() {
      return this.$store.state.at.captchaType;
    },

    /** get site key ArCaptcha from env */
    arcaptchaSiteKey() {
      return window.env.VUE_APP_ARCAPTCHA_SITE_KEY
    },

    /** get state load recaptcha */
    loadRecaptcha() {
      return this.$store.state.at.recaptchaLoaded
    },

    /** get state failed recaptcha */
    recaptchaLoadedFailed() {
      return this.$store.state.at.recaptchaLoadedFailed
    },

    gitCommit() {
      return process.env.VUE_APP_GIT_HASH
    },

    showCommit(){
      return process.env.VUE_APP_SHOW_COMMIT_ID
    },
  },
  watch: {
    pinCode() {
      this.pinCode.length == 6 ? this.checkIdentification() : ''
    },
  },
  async mounted() {
    /** internet connection detector
    * if offline => show component disConnect
   */
    window.addEventListener('offline', () => { this.offlineAction() });
    if (!window.navigator.onLine) {
      this.offlineAction()
    }

    /** get 2FA State from localStorage for login wia 2FA */
    if (get2FA()) {
      this.googleAuthenticatorStatus = true
    }

    await this.detectTypeCaptcha();
  }
}
</script>

<style lang="scss">
@import '~pretty-checkbox/src/pretty-checkbox.scss';

.login-section {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-content {
    box-shadow: 0 0px 10px #2a2a3a;
    background-color: #1e1e2f;
    border-radius: 15px;
    padding: 30px;
    .login-header {
      padding: 15px 150px;
      background: #ffb115;
      border-radius: 10px;
      transform: translateY(-80%);
      margin-bottom: -15px;
      white-space: nowrap;
      text-align: center;
    }
    .brand {
      margin: 0 0 30px 0;
      text-align: center;
      position: relative;
      &::after,
      &::before {
        content: '';
        width: 35%;
        height: 1px;
        background: rgba(255, 255, 255, 0.171);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &::before {
        left: 25%;
      }
      &::after {
        right: -9%;
      }
    }
    .login-main {
      .label {
        p {
          color: #6b7795;
        }
      }
      input {
        color: #6b7795;
        background: transparent;
        border: 0.5px solid #6b77955b;
        box-shadow: 0 0px 10px #2a2a3a;
        font-family: 'iranyekan';
      }
      .password {
        margin: 40px 0 0 0;
      }
    }
    .login-footer {
      margin: 40px 0 0 0;
      .btn-warning {
        width: 250px;
        height: 45px;
        margin: auto;
        background: #ffb115;
        border: 1px solid #ffb115;
        font-family: 'iranyekan';
        color: #fff;
        transition: all 0.3s linear;
        outline: none;
        &:hover {
          color: #b47800;
          background: #ffcc66 !important;
          border: 1px solid #b47800;
          transition: all 0.3s linear;
        }
        &:active,
        &:focus {
          background: #ffb115 !important;
          border: 1px solid #ffb115;
        }
      }
    }
  }
  @media (max-width: 1400px) {
    .password,
    .login-footer {
      margin: 20px 0 0 0 !important;
    }
  }
  @media (max-width: 767.9px) {
    .login-content {
      width: 85%;
      padding: 30px 10px !important;

      .login-header {
        padding: 15px 50px !important;
        width: 100%;
      }
      .login-footer {
        .btn-warning {
          width: 95% !important;
        }
      }
    }
  }
  // disabled countdown (resent code)
  span.resend-disabled,
  .resend {
    opacity: 0.7;
    font-weight: bold;
    color: #6a6a6a;
  }
  .pincode {
    margin-bottom: 40px;
    .vue-pincode-input-wrapper {
      .vue-pincode-input {
        padding: 0 !important;
        height: 55px !important;
        max-width: 51px !important;
        margin: 0 9px !important;
        background: #f2f2f2 !important;
        @media (max-width: 550px) {
          height: 34px !important;
          max-width: 29px !important;
          margin: 0 3px !important;
        }
      }
    }
  }
  .resend {
    cursor: pointer;
  }

  .switch-authenticator {
    span {
      color: var(--black);
    }

    img {
      width: 30px !important;
    }

    input#authenticator {
      height: 0;
      width: 0;
      visibility: hidden;
      position: absolute;
      &:checked + label {
        background: #ffb115;
      }
      &:checked + label:after {
        background: #ffffff;
        left: 74%;
      }
    }
    label {
      cursor: pointer;
      width: 50px;
      height: 25px;
      background: #d0d0d070;
      display: block;
      border-radius: 100px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 13px;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        background: #969696;
        border-radius: 90px;
        transition: 0.3s;
      }
    }
  }
}

.arcaptcha-vue {
  display: flex;
  justify-content: center;
}
</style>