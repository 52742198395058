<template>
  <div class="content">
    <Setting></Setting>
    <fade-transition :duration="100" mode="out-in" class="px-0">
      <!-- your content here -->
      <router-view></router-view>
    </fade-transition>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import Setting from '../components/Setting.vue'
export default {
  components: {
    FadeTransition,
    Setting
  }
};
</script>
