<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="user-details d-flex my-4">
          <p
            class="mx-5"
            :class="{ activePanel: tab1Active }"
            @click="tab1"
          >
            تب 1
          </p>
          <p
            class="mx-5"
            :class="{ activePanel: tab2Active }"
            @click="tab2"
          >
            تب 2  
          </p>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
/**import mixin */
import Helper from "../../../settings/Helper";

export default {
  name: "UserDetailContainer",
  props: ["id"],
  mixins: [Helper],
  async mounted() {
    this.$rtl.enableRTL();
  },
  components: {},
  methods: {
    tab1() {
      this.$router.push({ path: "tab1", replace: true });
    },
    tab2() {
      this.$router.push({ path: "tab2", replace: true });
    },
  },
  computed: {
    tab1Active() {
      return this.$router.history.current.name == "tab1";
    },
    tab2Active() {
      return this.$router.history.current.name == "tab2";
    },
  },
};
</script>

<style lang="scss" scoped>
.user-details {
  p {
    cursor: pointer;
    padding: 0 0 15px 0;
    &:hover {
      transform: scale(0.95);
    }
  }
  .activePanel {
    border-bottom: 1.5px solid #fff;
  }
}
</style>
