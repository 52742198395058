<template>
  <section class="personal-permission">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!-- info -->
          <card>
            <div class="card-header">سطح دسترسی کاربر</div>
            <!-- the page content  -->
            <div class="card-body">
              <!-- row one (user id and birthday) -->
              <div class="row">
                <!-- user id -->
                <div class="col-12 col-md-6 col-lg-3" v-for="(item, index) in permissions" :key="index">
                  <base-checkbox v-model="item.value"> {{ item.title }} </base-checkbox>
                </div>
              </div>
              <div class="col-12 mt-4">
                <div class="submit">
                  <button class="btn-primary" @click="submit">ذخیره</button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'UserPersonalInfo',
  mixins: [Helper],
  data() {
    return {
      permissions: null,
      id: null,

    }
  },
  methods: {
    async loadItems() {
      try {

        let req = await this.$axios({
          method: 'get',
          url: '/Organizer/User/PermissionListGet',
          headers: {
            uIdCode: this.id
          }
        })

        let permission = req.data.data
        this.permissions = permission.map(function (item) {
          return {
            id: item.id,
            title: item.title,
            value: item.value == "1" ? true : false,
          }
        })


      } catch (error) {
        console.log(error)
      }
    },

    /** submit permission on user */
    async submit() {
      try {
        // console.log(this.permissions)

        let permissions = this.permissions
        permissions = permissions.map(function (item) {
          return {
            key: item.id,
            value: item.value
          }
        })
        await this.$axios({
          url: 'Organizer/User/PermissionSet',
          data: {
            userId: this.id,
            permissions: permissions
          }
        })

        return this.$notify({ text: 'عملیات با موفقیت انجام شد', type: 'success' })
      } catch (error) {
        console.log(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()
    this.id = this.$router.history.current.params.id

    this.loadItems()
  },
  components: {
  },
  computed: {

  }
}

</script>

<style lang="scss" scoped>
.personal-permission {
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
  .btn-document {
    width: 50px;
    border-radius: 50%;
    height: 50px;
    padding: 4px;
    /* border: 1px; */
    background: #4880db;
    border: none;
    cursor: pointer;
    &:hover {
      background: #8dade0;
      padding: 0px;
      outline: 4px solid #4880db;
    }
  }

  .btn-action {
    min-width: 70px;
    border-radius: 4px;
    padding: 2px;
    margin-right: 10px;
    /* border: 1px; */
    border: none;
    background: rgb(225, 223, 223);
    cursor: pointer;
    &:hover {
      padding: 6px;
    }
  }
  .btn-action-active {
    background: #ffb115;
  }
}
</style>
