<template>
  <div class="my-3 recaptcha">
    <!-- alert verified -->
    <div class="alert my-2" @click="reloadPage()">
      <div class="icon mx-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.032" height="14.25" viewBox="0 0 15.032 14.25">
          <g id="Group_3592" data-name="Group 3592" transform="translate(-0.304 -0.547)">
            <path
              id="Path_14972"
              data-name="Path 14972"
              d="M1.223,11.866,5.776,2.572a2.276,2.276,0,0,1,4.088,0l4.552,9.294c.153.312.261.69.02.94-1.59,1.654-11.643,1.654-13.234,0C.963,12.556,1.071,12.178,1.223,11.866Z"
              transform="translate(0 0)"
              fill="#fff7ef"
              stroke="#ffb115"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path id="Path_14973" data-name="Path 14973" d="M6.984,5.234V7.813" transform="translate(0.818 0.544)" fill="none" stroke="#ffb115" stroke-linecap="round" stroke-width="1.5" />
            <circle id="Ellipse_701" data-name="Ellipse 701" cx="1.13" cy="1.13" r="1.13" transform="translate(6.672 10.08)" fill="#ffb115" />
          </g>
        </svg>
      </div>
      <div class="text">
        <span>متاسفانه به دلیل اختلال در برقراری ارتباط، برخی سرویس ها به درستی بارگزاری نشده اند. <b>برای بارگزاری مجدد صفحه اینجا کلیک کنید</b></span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'failedRecaptcha',
  methods: {
    reloadPage() {
      location.reload();
    }
  }

}
</script>

<style scoped lang='scss'>
.recaptcha {
  max-width:435px;
  //** alert */
  .alert {
    border-radius: 5px;
    margin: 0 7px;
    background-color: tomato;
    // background-color: var(--alert);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 7px;
    .icon {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      svg {
        path {
          fill: transparent !important;
        }
      }
    }
    .text {
      span {
        font-size: 12px !important;
        font-weight: bold;
        color: whitesmoke;
        text-align: justify;
        cursor: pointer;
        b {
          color: white !important;
        }
      }
    }
  }
}
</style>