<template>
  <section class="notification-edit">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ValidationObserver ref="contentValidation">
            <!-- card 1 content -->
            <div class="col-12">
              <card>
                <div class="card-header">
                  <div class="card-title d-flex align-items-center justify-content-between">
                    <h6>اعمال بر روی کاربران</h6>
                  </div>
                </div>
                <!-- the page content  -->
                <div class="card-body">
                  <div class="row my-3">
                    <!-- کابران سطح معامله -->
                    <div class="date-modify col-12 col-md-6 col-lg-4">
                      <ValidationProvider name="کابران سطح معامله" rules="required" v-slot="{ errors }">
                        <span> کابران سطح معامله</span>
                        <input type="text" v-model="userIDsOfTraderLevel" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- کاربران سطح زیرمجموعه -->
                    <div class="date-modify col-12 col-md-6 col-lg-4">
                      <ValidationProvider name="کاربران سطح زیرمجموعه" rules="required" v-slot="{ errors }">
                        <span> کاربران سطح زیرمجموعه</span>
                        <input type="text" v-model="userIDsOfReferralLevel" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- کاربران معامله گر در بازار -->
                    <div class="date-modify col-12 col-md-6 col-lg-4">
                      <ValidationProvider name="کاربران معامله گر در بازار" rules="required" v-slot="{ errors }">
                        <span> کاربران معامله گر در بازار</span>
                        <input type="text" v-model="userIDsOfMarketTraders" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- کاربران مالک ارز -->
                    <div class="date-modify col-12 col-md-6 col-lg-4">
                      <ValidationProvider name="کاربران مالک ارز" rules="required" v-slot="{ errors }">
                        <span> کاربران مالک ارز</span>
                        <input type="text" v-model="userIDsOfSymbolHolders" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- کاربران انتخابی -->
                    <div class="date-modify col-12 col-md-6 col-lg-4">
                      <ValidationProvider name="کاربران انتخابی" rules="required" v-slot="{ errors }">
                        <span> کاربران انتخابی</span>
                        <input type="text" v-model="userIDsOfCustom" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </ValidationObserver>

          <div class="col-12">
            <div class="submit">
              <button class="btn-primary" @click="submit">ذخیره</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


/**import mixin */
import Helper from '../../../settings/Helper'



import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
localize('fa', fa)


export default {
  name: 'UserPersonalInfo',
  mixins: [Helper],
  data() {
    return {
      id: null,
      userIDsOfTraderLevel: null,
      userIDsOfReferralLevel: null,
      userIDsOfMarketTraders: null,
      userIDsOfSymbolHolders: null,
      userIDsOfCustom: null,
    }
  },
  methods: {

    // submit save
    async submit() {
      try {


        /**validate the form */
        if (! await this.$refs.contentValidation.validate()) return this.$notify({ text: 'لطفا خطاهای رخ داده را برطرف نمایید', type: 'error' })


        this.isLoading = true

        let data = {
          id: parseInt(this.id ? this.id : 0),
          setConditions: [
            {
              key: 'userIDsOfTraderLevel',
              value: this.userIDsOfTraderLevel
            },
            {
              key: 'userIDsOfReferralLevel',
              value: this.userIDsOfReferralLevel
            },
            {
              key: 'userIDsOfMarketTraders',
              value: this.userIDsOfMarketTraders
            },
            {
              key: 'userIDsOfSymbolHolders',
              value: this.userIDsOfSymbolHolders
            },
            {
              key: 'userIDsOfCustom',
              value: this.userIDsOfCustom
            },
          ]
        }

        await this.$axios({
          url: 'Organizer/Notification/UserSet',
          data: data
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.isLoading = false
        this.$router.push({ path: '/dashboard/notification', replace: true })
      } catch (error) {
        console.error(error)
      }
    },


  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  async mounted() {

    this.$rtl.enableRTL()

    this.id = this.$router.history.current.params.id


  },

}
</script>

<style lang="scss" scoped>
.notification-edit {
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  textarea {
    background-color: transparent !important;
    border: 1px solid #ffffeb;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    direction: ltr;
    color: #ffffeb67;
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    &:focus-visible {
      outline: none !important;
      color: #ffffeb;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }

  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
