<template>
  <nav class="navbar navbar-expand-lg mt-2 pb-0" :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }">
    <div>
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
          <button type="button" class="navbar-toggler" @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" @click="dashboard"></a>
      </div>
      <button class="navbar-toggler d-none" type="button" @click="toggleMenu" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-label="Toggle navigation">
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu" v-if="false">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <div class="search-bar input-group" @click="searchModalVisible = true">
              <!-- <input type="text" class="form-control" placeholder="Search...">
              <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div> -->
              <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal" v-if="false">
                <i class="tim-icons icon-zoom-split"></i>
              </button>
              <!-- You can choose types of search input -->
            </div>
            <modal :show.sync="searchModalVisible" class="modal-search" id="searchModal" :centered="false" v-if="false" :show-close="true">
              <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH" />
            </modal>
            <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item" menu-classes="dropdown-navbar">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="photo">
                  <img :src="require('../assets/img/anime3.png')" />
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">Log out</p>
              </a>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">پروفایل</a>
              </li>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">تنظیمات</a>
              </li>
              <div class="dropdown-divider"></div>
              <li class="nav-link" @click.prevent="logout">
                <a href="#" class="nav-item dropdown-item">خروج </a>
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'
import Modal from '@/components/Modal'

export default {
  components: {
    CollapseTransition,
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    async logout() {
      /**confirm logout */
      this.$confirm({
        message: "از خروج مطمئن هستید؟",
        button: {
          no: "خیر",
          yes: "بله"
        },

        /**
          * Callback Function
          * @param {Boolean} confirm
          */
        callback: confirm => {
          if (confirm) {
            this.$store.dispatch('at/logout')
          }
        }
      })


    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    dashboard() {
      this.$router.push({ path: "/", replace: true })
    }
  }
}
</script>

<style css>
.navbar-brand {
  cursor: pointer;
}
</style>
