<template>
  <section class="setting">
    <!-- toggle cog -->
    <div class="cog-icon" @click="settingSide = !settingSide">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256" style="fill: #000000">
        <g
          fill="#ffffff"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style="mix-blend-mode: normal"
        >
          <g transform="scale(5.12,5.12)">
            <path
              d="M47.16,21.221l-5.91,-0.966c-0.346,-1.186 -0.819,-2.326 -1.411,-3.405l3.45,-4.917c0.279,-0.397 0.231,-0.938 -0.112,-1.282l-3.889,-3.887c-0.347,-0.346 -0.893,-0.391 -1.291,-0.104l-4.843,3.481c-1.089,-0.602 -2.239,-1.08 -3.432,-1.427l-1.031,-5.886c-0.084,-0.478 -0.499,-0.828 -0.985,-0.828h-5.5c-0.49,0 -0.908,0.355 -0.987,0.839l-0.956,5.854c-1.2,0.345 -2.352,0.818 -3.437,1.412l-4.83,-3.45c-0.399,-0.285 -0.942,-0.239 -1.289,0.106l-3.887,3.887c-0.343,0.343 -0.391,0.883 -0.112,1.28l3.399,4.863c-0.605,1.095 -1.087,2.254 -1.438,3.46l-5.831,0.971c-0.482,0.08 -0.836,0.498 -0.836,0.986v5.5c0,0.485 0.348,0.9 0.825,0.985l5.831,1.034c0.349,1.203 0.831,2.362 1.438,3.46l-3.441,4.813c-0.284,0.397 -0.239,0.942 0.106,1.289l3.888,3.891c0.343,0.343 0.884,0.391 1.281,0.112l4.87,-3.411c1.093,0.601 2.248,1.078 3.445,1.424l0.976,5.861c0.079,0.481 0.496,0.834 0.985,0.834h5.5c0.485,0 0.9,-0.348 0.984,-0.825l1.045,-5.89c1.199,-0.353 2.348,-0.833 3.43,-1.435l4.905,3.441c0.398,0.281 0.938,0.232 1.282,-0.111l3.888,-3.891c0.346,-0.347 0.391,-0.894 0.104,-1.292l-3.498,-4.857c0.593,-1.08 1.064,-2.222 1.407,-3.408l5.918,-1.039c0.479,-0.084 0.827,-0.5 0.827,-0.985v-5.5c0.001,-0.49 -0.354,-0.908 -0.838,-0.987zM25,32c-3.866,0 -7,-3.134 -7,-7c0,-3.866 3.134,-7 7,-7c3.866,0 7,3.134 7,7c0,3.866 -3.134,7 -7,7z"
            ></path>
          </g>
        </g>
      </svg>
    </div>

    <!-- setting side -->
    <div class="setting-side" :class="{ 'open-setting-side': settingSide }">
      <!-- close setting side -->
      <div class="close-setting-side" @click="settingSide = !settingSide">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256" style="fill: #000000">
          <g
            fill="#ffffff"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <g transform="scale(8.53333,8.53333)">
              <path
                d="M7,4c-0.25587,0 -0.51203,0.09747 -0.70703,0.29297l-2,2c-0.391,0.391 -0.391,1.02406 0,1.41406l7.29297,7.29297l-7.29297,7.29297c-0.391,0.391 -0.391,1.02406 0,1.41406l2,2c0.391,0.391 1.02406,0.391 1.41406,0l7.29297,-7.29297l7.29297,7.29297c0.39,0.391 1.02406,0.391 1.41406,0l2,-2c0.391,-0.391 0.391,-1.02406 0,-1.41406l-7.29297,-7.29297l7.29297,-7.29297c0.391,-0.39 0.391,-1.02406 0,-1.41406l-2,-2c-0.391,-0.391 -1.02406,-0.391 -1.41406,0l-7.29297,7.29297l-7.29297,-7.29297c-0.1955,-0.1955 -0.45116,-0.29297 -0.70703,-0.29297z"
              ></path>
            </g>
          </g>
        </svg>
      </div>

      <!-- content -->
      <div class="setting-content">
        <label class="typo__label text-white">منطقه زمانی</label>
        <multiselect v-model="zoneSelected" :options="timezones" placeholder="یک مورد را انتخاب کنید"></multiselect>

        <!-- btn action -->
        <div class="mt-3 d-flex justify-content-end">
          <button class="btn btn-success" @click="setZone()">ذخیره</button>
        </div>
      </div>
    </div>

    <!-- blur -->
    <div class="blur-bg" v-if="settingSide" @click="settingSide = !settingSide"></div>
  </section>
</template>

<script>
// Multiselect
import Multiselect from 'vue-multiselect'
import moment from 'moment-timezone';

export default {
  data() {
    return {
      settingSide: false,
      zoneSelected: null,
    }
  },
  components: {
    Multiselect
  },
  methods: {
    setZone() {
      try {
        this.$notify({
          text: `منطقه زمانی به ${this.zoneSelected} تغییر کرد`
        })
        localStorage.setItem('zone', this.zoneSelected);
        this.settingSide = false;
        location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    timezones() {
      return moment.tz.names();
    }
  },
  mounted() {
    console.log(' window.env.TimeZone', window.env.TimeZone);
    this.zoneSelected = localStorage.getItem('zone') ? localStorage.getItem('zone') : window.env.TimeZone
  }
}
</script>

<style lang="scss" scoped>
section.setting {
  position: relative;
  .cog-icon {
    position: fixed;
    left: 0;
    top: 50%;
    z-index: 98;
    width: 50px;
    height: 50px;
    border-radius: 0 7px 7px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #404364;
    cursor: pointer;
    svg {
      animation: cog 5s linear 0s infinite;
      @keyframes cog {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  .setting-side {
    width: 350px;
    height: 100vh;
    background: #404364;
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 99;
    transition: all 0.1s linear;
    padding: 10px 15px;

    //** close setting side */
    .close-setting-side {
      width: 35px;
      height: 35px;
      border-radius: 50px;
      background: #ffffff30;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10px;
      right: -40px;
      z-index: 1061;
      cursor: pointer;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    //** open side */
    &.open-setting-side {
      left: 0;
    }
  }

  //** blur bg */
  .blur-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 98;
    background: #00000099;
  }
}
</style>