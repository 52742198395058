<template>
  <section class="personal-info">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-6">
          <card>
            <!-- the page content  -->
            <div class="card-body">
              <!-- row one (user id and birthday) -->
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- user id -->
                <div class="user-id w-100 w-lg-50 d-flex flex-column justify-content-between">
                  <span>شناسه کاربر</span>
                  <p>{{ userID }}</p>
                </div>
                <!-- birthday -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <span> تاریخ تولد </span>
                  <input type="text" class="birthday" v-model="birthday" :disabled="disabled" :class="{ flexible: !disabled }" />
                </div>
              </div>

              <!-- row three (first last name ) -->
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- first name -->
                <div class="date-created w-100 w-lg-50">
                  <span>نام </span>
                  <input type="text" v-model="firstName" :disabled="disabled" :class="{ flexible: !disabled }" />
                </div>
                <!-- last name -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <span> نام خانوادگی</span>
                  <input type="text" v-model="lastName" :disabled="disabled" :class="{ flexible: !disabled }" />
                </div>
              </div>
              <!-- row four ( phone number natinal code ) -->
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- phone number  -->
                <div class="date-created w-100 w-lg-50">
                  <span>شماره همراه </span>
                  <input type="text" v-model="phoneNumber" disabled />
                </div>
                <!-- national code -->
                <div class="national-code w-100 w-lg-50 mx-2">
                  <span>کد ملی</span>
                  <input type="text" v-model="nationalCode" disabled />
                </div>
              </div>
              <!-- row four (date modify) -->
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- date created -->
                <div class="date-created w-100 w-lg-50">
                  <span>تاریخ ایجاد</span>
                  <input type="text" v-model="dateCreated" disabled />
                </div>
                <!-- date modify -->
                <div v-if="false" class="date-modify w-100 w-lg-50 mx-2">
                  <span>تاریخ ویرایش</span>
                  <input type="text" v-model="dateModify" disabled />
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="col-12 col-xl-6">
          <card>
            <div class="card-header">
              <div class="card-title">
                <h6>اطلاعات (سطح 1)</h6>
              </div>
            </div>
            <!-- the page content  -->
            <div class="card-body">
              <!-- phone  Confirmation -->
              <div v-if="false" class="phone d-flex align-items-center justify-content-between my-3">
                <p>تایید شماره همراه</p>
                <div class="switch">
                  <input type="checkbox" class="toggle" v-model="phoneConfirmation" />
                </div>
              </div>
              <!-- profile Confirmation -->
              <div class="profile d-flex align-items-center justify-content-between my-3">
                <p>تایید پروفایل</p>
                <div class="switch">
                  <input type="checkbox" disabled class="toggle" v-model="profileConfirmation" />
                </div>
              </div>
              <!-- document Confirmation -->
              <div class="document d-flex align-items-center justify-content-between my-3">
                <p>تایید مدارک</p>
                <div class="switch">
                  <input type="checkbox" disabled class="toggle" v-model="documentConfirmation" />
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/**import mixin */
import Helper from '../../../settings/Helper'

/**import moment-jalali */
var moment = require('moment-jalaali')

export default {
  name: 'UserPersonalInfo',
  props: ['id'],
  mixins: [Helper],
  data() {
    return {
      disabled: true,
      userID: null,
      nationalCode: null,
      dateCreated: null,
      dateModify: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      birthday: null,
      fatherName: null,
      zipCode: null,
      dataRecive: null,
      // confirnation level one datas
      phoneConfirmation: false,
      profileConfirmation: false,
      documentConfirmation: false
    }
  },
  methods: {
    /**filter birthdate */
    checkDate(formatted) {
      return formatted > this.minAgeDate || formatted < this.maxAgeDate
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get user info */
    try {
      // get order
      let order = await this.$axios({
        method: 'get',
        url: 'invoice/' + this.$router.history.current.params.id
      })

      // get user detail
      let res = await this.$axios({
        method: 'get',
        url: 'profile/?user__uid=' + order.data.user.uid
      })

      this.userID = res.data.results[0].user.uid
      this.nationalCode = res.data.results[0].nid
      this.birthday = res.data.results[0].birth_date
      this.firstName = res.data.results[0].first_name
      this.lastName = res.data.results[0].last_name
      this.phoneNumber = res.data.results[0].user.username
      this.dateCreated = this.jdate(res.data.results[0].created)

      this.zipCode = res.data.results[0].zip_code
      this.fatherName = res.data.results[0].father_name
      this.dataRecive = res.data.results[0]

      this.phoneConfirmation = res.data.results[0].user.is_active
      this.documentConfirmation = res.data.results[0].document_verified
      this.profileConfirmation = res.data.results[0].verified
    } catch (error) {
      console.error(error)
    }
  },
  components: {},
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
