<template>
  <div class="container-fluid">
    <UserDetailContainer></UserDetailContainer>
  </div>
</template>

<script>


import UserDetailContainer from "./UserDetailContainer"
export default {
  name: 'indexUserDetailContainer',
  props: ['id'],
  async mounted() {
    this.$rtl.enableRTL()

    /**get user info */
    if (!this.$store.state.at.firstName)
      this.$store.dispatch('at/initProfile', this.$router.history.current.params.id)
  },
  components: {
    UserDetailContainer
  }
 
}
</script>

<style lang="scss" scoped>
</style>